<template>
  <div class="feed-post" v-if="post && post.user" :key="post._id" icon="dots_loading" ref="cardRecycler">
    <div
      :class="`creator-post post-${post._id}-${index} ${deleted} ${post.cardClass || ''}`"
      :type="post.type"
      :style="`--cover:url(${getPostCover(post)})`"
    >
      <div class="creator-post-header" v-if="hasHeader">
        <div class="creator-post-header-left">
          <router-link :to="`/${post.user.user}`" class="creator-post-avatar-wrap">
            <UserAvatar :user="post.user" :type="post.type" size="60px" />
          </router-link>
          <div class="creator-post-header-user">
            <p class="name" bold capitalize>
              <router-link :to="`/${post.user.user}`">{{ post.user.name }}</router-link>
            </p>
            <p class="userName">
              <router-link :to="`/${post.user.user}`">@{{ post.user.user }}</router-link>
            </p>
          </div>
        </div>
        <div class="creator-post-header-right">
          <span class="post-timelapse">{{ getTimeLapse(post) }}</span>
          <a class="options-btn" @click="postContext(post)" v-if="!post.isAdmin"><iconic name="ellipsis-v" /></a>
        </div>
      </div>
      <div class="creator-post-message">
        <CreatorPostCardMessage v-if="post.message" :post="post" />
        <PostMediaInfo v-if="!hasHeader && !post.cleanPost" :post="post" @click="PostInfo(post)" />
      </div>
      <div :class="`creator-post-media ${post.media && post.media.length ? 'hasMedia' : ''}`">
        <PostMediaSlider v-if="post.media && post.media.length" :post="post" :settings="settings">
          <CreatorPostMedia
            v-for="(media, index) in post.media"
            :post="post"
            :media="media"
            :index="index"
            :key="`${post._id}-${index}`"
            @postView="$emit('postView')"
          />
        </PostMediaSlider>
        <CreatorPostSubscribe v-if="post.availableFor === 'subscriber' && post.subcribeStatus !== 'active'" :post="post" />
        <CreatorPostUnlock v-else-if="post.availableFor === 'purchase' && post.subcribeStatus !== 'purchased'" :post="post" />
      </div>
      <div :class="`creator-post-footer`" v-if="hasHeader && !post.isAdmin">
        <CreatorPostOptions :post="post" :class="`${post.media && post.media.length ? 'isMedia' : ''}`" />
        <CreatorPostCardLikes :post="post" />
        <CreatorPostCardStatus :post="post" />
      </div>
    </div>
  </div>
</template>

<script>
import HomeFeed from "../feed/HomeFeed.js";
import CreatorPostMedia from "./CreatorPostMedia.vue";
import CreatorPostOptions from "./CreatorPostOptions.vue";
import CreatorPostSubscribe from "./CreatorPostSubscribe.vue";
import CreatorPostUnlock from "./CreatorPostUnlock.vue";
import CreatorPostCardLikes from "./CreatorPostCardLikes.vue";
import CreatorPostStatus from "./CreatorPostStatus.vue";
import CreatorPostCardMessage from "./CreatorPostCardMessage.vue";
import PostMediaSlider from "../widgets/PostMediaSlider.vue";
import AdminPostReviewTools from "../admin/AdminPostReviewTools.vue";
import CreatorPostCardStatus from "./CreatorPostCardStatus.vue";
import PostMediaInfo from "../widgets/PostMediaInfo.vue";
export default {
  mixins: [HomeFeed],
  components: {
    CreatorPostOptions,
    CreatorPostSubscribe,
    CreatorPostUnlock,
    PostMediaSlider,
    CreatorPostStatus,
    CreatorPostCardLikes,
    AdminPostReviewTools,
    CreatorPostMedia,
    CreatorPostCardMessage,
    CreatorPostCardStatus,
    PostMediaInfo,
  },
  props: ["post", "index", "settings", "mode"],
  data: function() {
    return {
      messageLength: 130,
      moretxt: false,
    };
  },
  methods: {
    expandMessage: function(post, index) {
      const postMessage = document.querySelector(`.post-${post._id}-${index} .creator-post-message`);
      const textMessage = postMessage.querySelector(".text");
      const expandBtn = postMessage.querySelector(".expand-btn span");
      postMessage.classList.toggle("expand");
      if (postMessage.classList?.contains("expand")) {
        textMessage.innerHTML = decodeURIComponent(post.message);
        expandBtn.innerHTML = "Leer menos";
      } else {
        textMessage.innerHTML = this.$global.getShort(decodeURIComponent(post.message), this.messageLength);
        expandBtn.innerHTML = "Leer más";
      }
    },
    postContext: function(post) {
      this.PostInfo(post);
    },
  },
  computed: {
    deleted: function() {
      return this.post.deleted ? "deleted" : "";
    },
    hasHeader: function() {
      return this.mode !== "plus" && this.mode !== "grid" && !this.post.cleanPost;
    },
  },
};
</script>

<style lang="scss">
.creator-post {
  position: relative;
  background: #fff;
  box-shadow: $dshadow;
  &.cleanPostCard.transparent {
    background: transparent;
    box-shadow: none;
  }
  &-header {
    display: flex;
    justify-content: space-between;
    &-left {
      display: flex;
      align-items: center;
    }
    &-user {
      .name {
        margin: 0 0 2px 0;
      }
      .userName {
        color: #444444;
        &:hover {
          color: $primary_color;
        }
      }
      .name,
      .userName {
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &-right {
      display: flex;
      padding: $mpadding/2;
      color: $sec_color;
      .post-timelapse {
        font-size: 80%;
        margin: $mpadding/1.3 $mpadding/2 0 0;
        text-align: right;
      }
      .options-btn {
        width: $mpadding * 2.5;
        height: $mpadding * 2.5;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 50%;
        &:hover {
          background: #eeeeee;
        }
      }
    }
    .UserAvatar {
      margin: $mpadding;
    }
  }
  &-message {
    .expand-btn {
      cursor: pointer;
      color: $sec_color;
      margin-left: $mpadding/2;
    }
  }
  &-media {
    position: relative;
    overflow: hidden;
    &-wrap {
      display: flex;
      img,
      video {
        width: 100%;
      }
    }
  }

  &.cleanPostCard {
    .creator-post-header,
    .creatorPostText {
      display: none;
    }
  }

  @media (min-width: $tablet_width) {
    border-radius: $mradius;
    &.cleanPostCard {
      .StoriesPostCard {
        border-radius: $mradius;
        overflow: hidden;
      }
    }
  }
}
</style>
