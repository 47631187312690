<template>
  <div class="post-unlock-wrap" v-if="post.media.length">
    <MarkOffer :text="`${discountPercent}% Off`" :post="post" v-if="isOffer" />
    <div class="post-unlock">
      <div class="post-unlock-icon">
        <figure><iconic name="lock-solid" /></figure>
      </div>
      <div class="post-unlock-content">
        <CreatorPostUnlockDiscount :post="post" v-if="onDiscount(post)" />
        <CreatorPostUnlockRegular :post="post" v-else />
        <UnlockButton @click="UnlockContent(post)" />
      </div>
    </div>
  </div>
</template>

<script>
import UnlockButton from "../buttons/UnlockButton.vue";
import CreatorPostUnlockRegular from "./CreatorPostUnlockRegular.vue";
import CreatorPostUnlockDiscount from "./CreatorPostUnlockDiscount.vue";
import MarkOffer from "../buttons/MarkOffer.vue";
export default {
  components: { UnlockButton, CreatorPostUnlockRegular, CreatorPostUnlockDiscount, MarkOffer },
  props: ["post"],
  data: function() {
    return {
      isOffer: null,
      discountPercent: null,
      discountLimit: null,
    };
  },
  methods: {
    onDiscount: function(post) {
      if (!post) return false;

      const discountDateLimit = post.discountDateLimit;
      const isTimeLeft = this.$global.isTimeLeft(discountDateLimit);

      if (!isTimeLeft) return false;

      if (post.discountStatus === "on") {
        this.isOffer = true;
        this.discountPercent = post.discountPercent;
        this.discountLimit = post.discountDateLimit;
        return true;
      }
    },
  },
};
</script>
