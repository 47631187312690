export default {
  components: {
    VerifyUserWidget: () => import("@/components/verify-user/VerifyUserWidget.vue"),
    CreatorPostWidget: () => import("@/components/creator/CreatorPostWidget.vue"),
    CreatorSuggestBlock: () => import("@/components/creator/CreatorSuggestBlock.vue"),
    CreatorSubscriptionCard: () => import("@/components/creator/CreatorSubscriptionCard.vue"),
    StoriesPostCard: () => import("@/components/stories/StoriesPostCard.vue"),
    SuperAdminCreatorAccount: () => import("@/components/admin/SuperAdminCreatorAccount.vue"),
    FeedModeTabs: () => import("@/components/feed/FeedModeTabs.vue"),
    CreditsFeedPost: () => import("@/components/credits/CreditsFeedPost.vue"),
    PostConnectCard: () => import("@/components/creator/PostConnectCard.vue"),
  },
};
