<template>
  <div class="post-review-status">
    <div class="post-review-status-content">{{ $locale[post.adminStatus] }}</div>
  </div>
</template>

<script>
export default {
  props: ["post"],
};
</script>

<style lang="scss" scoped>
.post-review-status {
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
  padding: 0 $mpadding;
  &-content {
    padding: $mpadding;
    border-top: solid 1px $alto;
  }
}
</style>
