<template>
  <div :class="`file creator-post-media-wrap`" @click="postView">
    <video
      v-if="mediaType(media) === 'video'"
      class="homeVideo"
      width="100%"
      :poster="url_encode(getMediaPoster(media, post, index))"
      v-bind="videoAttributes"
      controlsList="nodownload"
    >
      <source :src="`${url_encode(media.src)}`" />
    </video>
    <img alt="Image" v-else-if="mediaType(media) === 'image'" :src="url_encode(media.src)" noevents loading="lazy" />
  </div>
</template>

<script>
export default {
  props: ["post", "index", "media"],
  data: function() {
    return {
      videoAttributes: {
        controls: true,
        playsinline: true,
        controlslist: "nodownload",
        noevents: true,
      },
    };
  },
  methods: {
    postView: function() {
      this.$emit("postView");
      if (this.post.type === "story") {
        this.setQuery({ story_view: this.post.user.user, post: this.post._id, mediaIndex: this.index });
      } else {
        this.setQuery({ post_view: this.post._id, mediaIndex: this.index });
      }
    },
  },
};
</script>
