<template>
  <div class="PostMediaInfo" @click="$emit('click')" cursor="pointer" active="button">
    <div class="PostMediaInfoLeft">
      <div class="MediaVideos">
        <div class="MediaTag" v-if="images">
          <span v-if="images">{{ images + " " + imageLabel }}</span>
        </div>
        <div class="MediaTag" v-for="(video, index) in mediaVideos" :key="index">
          <span>Video</span>
          <VideoDuration :video="video" />
        </div>
      </div>
    </div>
    <div class="PostMediaInfoRight">
      <div class="postMessagePlus">
        <span class="postTimeLapse">
          <span class="postDate">{{ getTimeLapse(post) }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["post"],
  computed: {
    media: function() {
      return this.post.media || [];
    },
    images: function() {
      return this.post.info && this.post.info.countImages;
    },
    videos: function() {
      return this.post.info && this.post.info.countVideos;
    },
    videoLabel: function() {
      return this.videos > 1 ? this.$locale["videos"] : this.$locale["video"];
    },
    imageLabel: function() {
      return this.images > 1 ? this.$locale["images"] : this.$locale["image"];
    },
    mediaVideos: function() {
      return this.media.filter((item) => Go.isMedia(item, "video"));
    },
  },
};
</script>

<style lang="scss">
.PostMediaInfo {
  padding: 0 0 0 $mpadding;
  font-size: 90%;
  display: flex;
  gap: $mpadding/2;
  justify-content: space-between;

  .PostMediaInfoLeft {
    display: flex;
    gap: $mpadding/2;
    flex-direction: column;
  }

  .MediaTag,
  .MediaVideo {
    display: inline-flex;
    background-color: $button_contrast;
    padding: $mpadding/4 $mpadding/2;
    border-radius: 2pc;
    opacity: 0.7;
    white-space: nowrap;
    gap: $mpadding/4;
    justify-content: center;
  }

  .postTimeLapse {
    padding: 0;
    opacity: 0.7;
  }

  .postDate {
    font-size: 90%;
    white-space: nowrap;
  }

  .PostMediaInfoRight {
    display: flex;
  }

  .MediaVideos {
    display: flex;
    gap: $mpadding/2;
    flex-wrap: wrap;
    font-size: 90%;
  }

  .postMessagePlus {
    margin: auto 0 0 0;
  }
}
</style>
