<template>
  <div v-if="$locale && post">
    <div class="post-unlock-price">
      <span class="icon"><iconic name="mf_credits_o" /></span>
      <span>{{ post.price }} {{ $locale["object"]["user-profile"]["credits-label"] }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["post"],
};
</script>
