<template>
  <div class="post-review" v-if="post">
    <div class="post-review-content" v-if="post.adminStatus === 'new'">
      <a class="post-review-button" @click="confirmReview('disapproved')">
        <iconic name="file_times" />
        <span>{{ $locale["words"]["disapprove"] }}</span>
      </a>
      <a class="post-review-button" @click="confirmReview('approved')">
        <iconic name="clipboard_check" />
        <span>{{ $locale["words"]["approve"] }}</span>
      </a>
    </div>
    <div class="post-review-content" v-else>
      <a class="post-review-button __disable third-font">
        <span>{{ $locale["words"][post.adminStatus] }}</span>
        <span class="reviewBy" v-if="post.lastUserReview" @click="userInfo(post)">
          <span>by</span>
          <span class="reviewUser" bold>@{{ post.lastUserReview.user }}</span>
        </span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: ["post"],
  methods: {
    confirmReview: function(status) {
      this.$store.state.posts.postReview = { post: this.post, status };
    },
    userInfo: function(post) {
      this.view({
        post,
        title: `@${post.lastUserReview.user}`,
        class: "menu bottom",
        animation: "bottomIn",
        component: () => import("./AdminPostReviewInfo.vue"),
        closeOutside: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.post-review {
  padding: 0 $mpadding;

  &-content {
    border-top: solid 1px $alto;
    padding: $mpadding/2 0;
    @include Flex(inherit, space-evenly, center);
  }

  &-button {
    @include Flex(inherit, center, center);
    flex: 1;
    color: rgba(0, 0, 0, 0.7);
    padding: $mpadding/2;
    border-radius: $mradius/2;
    gap: $mpadding/2;

    &:not(.__disable):hover {
      cursor: pointer;
      color: rgba(0, 0, 0, 1);
      background-color: #f5f5f5;
    }

    .iconic {
      @include Flex(inherit, center, center);
      margin: 0 $mpadding/2 0 0;
      font-size: 120%;
    }

    .reviewBy {
      @include Flex(inherit, center, center);
      gap: $mpadding/2;
      cursor: pointer;
    }

    .reviewBy:active .reviewUser {
      opacity: 0.5;
    }

    .reviewBy:hover .reviewUser {
      color: $primary_color;
    }
  }
}
</style>
