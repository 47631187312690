<template>
  <div class="unlock-discount" v-if="post">
    <p class="p-msg">¡{{ $locale["object"]["subscribe"]["subscribe-discount-label"] }}!</p>
    <div><ClockTimeLeft :date="post.discountDateLimit" /></div>
    <div class="unlock-discount-price">
      <div class="price-discount">
        <span v-html="`- ${percent} %`"></span>
      </div>
      <div class="price-before">
        <span v-html="`${priceByMonth}  ${$locale['object']['user-profile']['credits-label']}`"></span>
      </div>
      <div class="price-after">
        <span v-html="`${Go.number_format(getPostPrice(post))}  ${$locale['object']['user-profile']['credits-label']}`"></span>
      </div>
    </div>
  </div>
</template>

<script>
import ClockTimeLeft from "../widgets/ClockTimeLeft.vue";
export default {
  components: { ClockTimeLeft },
  props: ["post"],
  data: function() {
    return {
      percent: this.post?.discountPercent,
      priceByMonth: this.post?.price,
    };
  },
};
</script>

<style lang="scss" scoped>
.unlock-discount {
  &-price {
    font-size: 80%;
    padding: $mpadding/2 0;
    .price-discount {
      margin: 0 0 3px 0;
    }
    .price-before {
      font-size: 90%;
      margin: 0 0 3px 0;
      text-decoration: line-through;
    }
    .price-after {
      font-size: 120%;
    }
  }
}
</style>
