<template>
  <div class="creator-post-options">
    <div class="content">
      <div class="content-left">
        <div class="like-post-btn">
          <a v-if="likeing" class="content-button"><iconic name="gloading" /></a>
          <a v-else-if="currentPost.isLike" class="content-button" @click="likePost">
            <iconic name="heart" />
          </a>
          <a v-else class="content-button" @click="likePost">
            <iconic name="heart-o" />
          </a>
        </div>
        <a class="content-button" @click="goToComments(post)"><iconic name="comment-o" /></a>
        <a class="content-button --label" @click="goInsentive('gift')">
          <span><iconic name="gift" /></span>
          <label>{{ $locale["object"]["post-context"]["gift-label"] }}</label>
        </a>
        <a class="content-button --label" @click="goInsentive('tip')">
          <span v-size="'120%'"><iconic name="dollar-circle" /></span>
          <label>{{ $locale["object"]["post-context"]["tip-label"] }}</label>
        </a>
        <a class="content-button --label" @click="goInsentive('flowers')">
          <span><iconic name="flower" /></span>
          <label>{{ $locale["object"]["post-context"]["flower-label"] }}</label>
        </a>
      </div>
      <div class="content-right">
        <div class="save-post-btn">
          <a v-if="saving" class="content-button"><iconic name="gloading" /></a>
          <a v-else-if="currentPost.isSaved" class="content-button" @click="savePost">
            <iconic name="bookmark" />
          </a>
          <a v-else class="content-button" @click="savePost">
            <iconic name="bookmark-o" />
          </a>
        </div>
      </div>
    </div>
    <div class="statistics" v-if="showStatistics">
      <div class="statistics-likes pointer" v-if="currentPost.likes" @click="setQuery({ likes: post._id })">
        <span num>{{ number_format(currentPost.likes) }}</span>
        <span>{{ $locale["words"]["likes"] }}</span>
      </div>
      <div class="statistics-comments pointer" v-if="currentPost.comments" @click="setQuery({ comments: post._id })">
        <span num v-if="currentPost.comments">{{ number_format(currentPost.comments) }}</span>
        <span>{{ $locale["words"]["comments"] }}</span>
        <span class="__cicon"><iconic name="comment_dots" /></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    Comments: () => import("../comments/Comments.vue"),
  },
  props: ["post"],
  data: function() {
    return {
      currentPost: this.post,
      saving: false,
      likeing: false,
    };
  },
  methods: {
    savePost: function() {
      if (!this.$user) {
        this.requestUserLogin({ post: this.post });
        return;
      }
      this.saving = true;
      const callback = (response) => {
        this.saving = false;
        if (response?.data?.success) {
          if (response.data.unsave) {
            this.currentPost.isSaved = false;
          } else {
            this.currentPost.isSaved = true;
          }
        } else {
          this.ialert.go({ type: "notification", title: "Error", message: response.data?.message || response.message });
        }
      };
      this.$store.dispatch("savePost", { id: this.post._id, callback, creator: this.post.user._id });
    },
    likePost: function() {
      if (!this.$user) {
        this.requestUserLogin({ post: this.post });
        return;
      }

      this.likeing = true;
      const callback = (response) => {
        this.likeing = false;
        if (response?.data?.success) {
          if (response.data.unlike) {
            this.currentPost.isLike = false;
          } else {
            this.currentPost.isLike = true;
          }
          this.currentPost.likes = response.data.likes;
          this.post.likes = response.data.likes;
          this.post.latestLikes = response.data.latestLikes;
        } else {
          this.ialert.go({ type: "notification", title: "Error", message: response.data?.message || response.message });
        }
      };
      this.$store.dispatch("likePost", { id: this.post._id, callback, creator: this.post.user._id });
      this.$emit("like");
    },
  },
  computed: {
    showStatistics: function() {
      return this.currentPost.likes || this.currentPost.comments;
    },
  },
};
</script>

<style lang="scss" scoped>
.creator-post-options {
  position: relative;
  z-index: 1;
  .content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-left,
    &-right {
      display: flex;
    }
    &-button {
      display: inline-block;
      padding: $mpadding/1.5 $mpadding/1.5;
      cursor: pointer;
      color: $sec_color;
      .iconic {
        font-size: 25px;
        @include Flex(inherit, center, center);
      }
      &:hover {
        color: rgb(83, 83, 83);
      }
      &.--label {
        display: flex;
        align-items: center;
        label {
          display: none;
          margin: 0 0 0 $mpadding/2;
          pointer-events: none;
        }
      }
    }
  }
  .statistics {
    display: flex;
    align-items: center;
    padding: $mpadding/2 $mpadding $mpadding/2 $mpadding;
    color: #444;
    &-likes,
    &-comments {
      @include Flex(inherit, center, center);
      font-size: 90%;
      font-weight: bold;
      [num] {
        margin: 0 $mpadding/2.2 0 0;
      }
      &[class*="-likes"] {
        margin: 0 $mpadding 0 0;
      }
      .__cicon {
        margin: 0 0 0 $mpadding/2;
      }
    }
  }
  @media (min-width: $mobile_m_width) {
    .content {
      &-button {
        padding: $mpadding/1.5 $mpadding/1.2;
      }
    }
  }
  @media (min-width: $tablet_width) {
    .content {
      &-button {
        &.--label {
          label {
            display: inline-block;
          }
        }
      }
    }
  }
}
</style>
