<template>
  <div class="unlock-action">
    <a class="unlock-button primary-button" @click="$emit('click')">
      <span v-if="loading"><iconic name="gspinner" /></span>
      <span v-else>{{ $locale["object"]["subscribe"]["unlock-label-btn"] }} {{ label }}</span>
    </a>
  </div>
</template>

<script>
export default {
  props: ["loading", "label"],
};
</script>

<style lang="scss" scoped>
.unlock-action {
  .unlock-button {
    display: inline-block;
    width: 100%;
    background: $primary_color;
    max-width: 256px;
    padding: $mpadding/1.5 $mpadding;
    border-radius: 4pc;
  }
}
</style>
