<template>
  <div class="creator-post-likes" v-if="hasLikes">
    <div class="creator-post-likes-users">
      <UserAvatar v-for="(like, index) in latestLikes" :key="like._id" :user="like.user" size="36px" :style="`--index:${index}`" />
    </div>
    <div class="creator-post-likes-legend">
      <span>{{ $locale.words["liked_to"] }}</span>
      <span class="__user" v-if="userLike && userLike.user">@{{ displayUser(userLike.user) }}</span>
      <span v-if="leftMore">
        <span lowercase>{{ $locale.words["and"] }}</span>
        <a class="__link" @click="setQuery({ likes: post._id })">{{ leftMore }} {{ $locale.words["others"] }}</a>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["post"],
  methods: {
    displayUser: function(user) {
      if (user.user == user.email) {
        return user.name;
      }
      return user.user;
    },
  },
  computed: {
    hasLikes: function() {
      return this.post.likes > 0;
    },
    latestLikes: function() {
      return this.post.latestLikes;
    },
    userLike: function() {
      let theUser = {};
      if (this.latestLikes && this.latestLikes.length) {
        let index = this.randomNumber(0, this.latestLikes.length - 1, { int: true });
        theUser = this.latestLikes[index];
      }
      return theUser;
    },
    leftMore: function() {
      return this.post.likes - 1;
    },
  },
};
</script>

<style lang="scss">
.creator-post-likes {
  position: relative;
  display: flex;
  gap: $mpadding;
  align-items: center;
  padding: $mpadding/2 $mpadding $mpadding $mpadding;
  &-users {
    display: flex;
    padding: 0 0 0 $mpadding/4;
  }
  .user-avatar {
    border: solid 3px #fff;
    position: relative;
    margin-left: calc(0px - #{$mpadding/2});
    background-color: $primary_color;
  }
  &-legend {
    .__user {
      font-weight: bold;
      margin: 0 $mpadding/2;
    }
    .__link {
      font-weight: bold;
      margin: 0 $mpadding/2;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
