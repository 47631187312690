<template>
  <div class="postCardMessage">
    <div class="creator-post-message">
      <div v-if="!post.cleanPost">
        <div class="text creatorPostText" v-html="isMoreText(decodeURIComponent(post.message), messageLength)" break-all></div>
        <a v-if="moretxt" @click="expandMessage(post, index)" class="expand-btn">
          <span v-bind="(moretxt = false)" nowrap>{{ $locale["read_more"] }}</span>
        </a>
      </div>
      <div class="message-comp" v-if="post.messageComponent">
        <component :is="post.messageComponent" :post="post"></component>
      </div>
    </div>
  </div>
</template>

<script>
import CreatorPostComponents from "./CreatorPostComponents.js";
export default {
  mixins: [CreatorPostComponents],
  props: ["post"],
  data: function() {
    return {
      messageLength: 130,
      moretxt: false,
    };
  },
  methods: {
    isMoreText: function(text, limit) {
      return this.$global.cleanHTML(text);
    },
  },
};
</script>
