<template>
  <div class="postCardStatus">
    <div class="postCardDeletedMessage" v-if="post.deleted">
      <iconic name="trash" />
      <span>{{ $locale["this_post_was_deleted"] }}</span>
    </div>
    <AdminPostReviewTools :post="post" v-else-if="$isAdmin" />
    <CreatorPostStatus :post="post" v-else-if="$isCreator && isMePost" />
  </div>
</template>

<script>
export default {
  props: ["post"],
  components: {
    CreatorPostStatus: () => import("./CreatorPostStatus.vue"),
    AdminPostReviewTools: () => import("../admin/AdminPostReviewTools.vue"),
  },
  computed: {
    isMePost: function() {
      return this.post.user.user === this.$user.user;
    },
  },
};
</script>
